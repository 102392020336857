<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Feed</strong>
            </div>
            <div class="text-muted">Edit publisher feed information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher feed's name.</span>
                  </template>
                  <a-input type="text" placeholder="Feed Name" v-decorator="[ 'name', { initialValue: pubfeedData.Name,
                 rules : [
                   { required: true, message: 'Name is required.' },
                   { max: 64, message: 'Maximum 64 characters allowed.'}
                   ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher feed's description.</span>
                  </template>
                  <a-input placeholder="Feed Description" v-decorator="['description', { initialValue: pubfeedData.Description,
                 rules: [
                      { max: 512, message: 'Maximum 512 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Fallback URL">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>URL where filtered impressions will go.</span>
                  </template>
                  <a-input type="url" placeholder="Feed Fallback URL" v-decorator="['fallbackUrl', { initialValue: pubfeedData.FallbackUrl,
                 rules: [
                      { max: 256, message: 'Maximum 256 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Determines how impressions are tracked.</span>
                  </template>
                  <a-select
                    v-decorator="['tracking', { initialValue: pubfeedData.ImpressionTrackingMethod }]"
                    placeholder="Impression Tracking Method"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option :key="0">Impression Pixel</a-select-option>
                    <a-select-option :key="1">Any Available Image</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. CPC">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad with bid below this value will not be shown to a publisher.</span>
                  </template>
                  <a-input type="number" min="0" step="0.0001" placeholder="Minimum CPC"
                           v-decorator="[ 'MinCPC', { initialValue: Math.round((pubfeedData.MinCPC + Number.EPSILON) * 10000) / 10000 ,
                  rules: [{validator: bidValidator}]}]">
                    <a-tooltip slot="addonBefore" trigger="click">
                      <a-icon type="dollar"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. CPC">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad with bid above this value will not be shown to a publisher.</span>
                  </template>
                  <a-input type="number" min="0" step="0.0001" placeholder="Maximum CPC"
                           v-decorator="[ 'MaxCPC', { initialValue: Math.round((pubfeedData.MaxCPC + Number.EPSILON) * 10000) / 10000,
                  rules: [{validator: bidValidator}]}]">
                    <a-tooltip slot="addonBefore" trigger="click">
                      <a-icon type="dollar"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID Mode">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Block or allow publisher sub IDs.</span>
                  </template>
                  <a-select
                    v-decorator="['SubidListMode', { initialValue: pubfeedData.SubidListMode }]"
                    placeholder="Subid List Mode"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option :key="0">Blacklist</a-select-option>
                    <a-select-option :key="1">Whitelist</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID List">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>List of sub IDs to block or allow.</span>
                  </template>
                  <a-select
                    v-decorator="['SubidList', { initialValue: (pubfeedData.SubidList === '' ? [] : newLineSplit(pubfeedData.SubidList)) }]"
                    placeholder="Subid List"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                    mode="tags"
                  >
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>List of domains or brand names to block.</span>
                  </template>
                  <a-select
                    v-decorator="['AdDomainsBrandsBlacklist', { initialValue:(pubfeedData.AdDomainsBrandsBlacklist === '' ? [] : newLineSplit(pubfeedData.AdDomainsBrandsBlacklist))}]"
                    placeholder="Ad Domains/Brands Blacklist"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                    mode="tags"
                  >
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of requests allowed per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Requests Daily Limit"
                           v-decorator="[ 'RequestsDailyLimit', { initialValue: pubfeedData.RequestsDailyLimit }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                           v-decorator="[ 'ClicksDailyLimit', { initialValue: pubfeedData.ClicksDailyLimit }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists Type">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Type of Keywords blacklists filtering.</span>
                  </template>
                  <a-select
                    v-decorator="['KeywordBlacklistsType', { initialValue: (typeof keywordListsType[pubfeedData.KeywordBlacklistsType] === 'undefined' ? null : keywordListsType[pubfeedData.KeywordBlacklistsType].Name) }]"
                    placeholder="Keyword Blacklists Type"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="keywordListType in keywordListsType" :key="keywordListType.Id">
                      {{ keywordListType.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Keyword lists to set to the publisher feed as blacklists.</span>
                  </template>
                  <a-select
                    v-decorator="['KeywordBlacklists', { initialValue: pubfeedData.KeywordBlacklists }]"
                    placeholder="Keyword Blacklists"
                    :showSearch="true"
                    :filterOption="true"
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="keywordList in activeKwlist" :key="keywordList.Id">{{ keywordList.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(pubfeedData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-2'"
                          @click.prevent="toggleFeedStatus">
                    {{ pubfeedData.Status == 0 ? 'Deactivate' : 'Activate' }} Feed
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import defaults from './defaults.json'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 3 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 18 },
}

export default {
  components: {},
  computed: {
    ...mapState(['publisher', 'kwlist']),
    pubfeedData: function () {
      let pubfeedData = this.$store.getters['pubfeed/getPubFeedData'](this.$route.params.id)
      // console.log(pubfeedData, 1)
      if (typeof pubfeedData === 'undefined') {
        this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
        pubfeedData = defaults
      }
      // console.log(pubfeedData, pubfeedData.Name, 2)
      if (typeof pubfeedData.Name === 'undefined') {
        pubfeedData = defaults
      }
      // console.log(pubfeedData)
      return pubfeedData
    },
    activeKwlist() {
      return this.$store.getters['kwlist/getActiveKwLists']
    },
  },
  data() {
    return {
      keywordLists: [],
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
    }
  },
  methods: {
    newLineSplit(string) {
      return string.split('\n')
    },
    bidValidator(rule, value, message) {
      const fields = this.form.getForm()
      const minCPC = parseFloat(fields.getFieldValue('MinCPC'))
      const maxCPC = parseFloat(fields.getFieldValue('MaxCPC'))

      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message((rule.field === 'MinCPC' ? 'Min. CPC' : 'Max. CPC') + ' can not be negative.')
      } else if (rule.field === 'MinCPC' && !isNaN(maxCPC) && minCPC > maxCPC) {
        message('Min. CPC can not be greater than Max. CPC.')
      } else if (rule.field === 'MaxCPC' && !isNaN(minCPC) && minCPC > maxCPC) {
        message('Max. CPC can not be less than Min. CPC.')
      }
      message()
    },
    toggleFeedStatus() {
      const status = this.pubfeedData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change feed status?',
        content: h => <div>{status} feed <strong>{this.pubfeedData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.pubfeedData.Status === 0 ? 1 : 0
          this.$store.dispatch('pubfeed/CHANGE_STATUS', {
            id: this.pubfeedData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.fallbackUrl !== '' && values.fallbackUrl) {
            values.fallbackUrl = values.fallbackUrl.replace(/\s/g, '')
          }
          this.$store.dispatch('pubfeed/UPDATE_PUBFEED', {
            id: this.pubfeedData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-feeds' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.pubfeedData[key] === 'undefined') ? '' : this.pubfeedData[key]
    },
  },
  created() {
    // console.log(this.activeKwlist)
    this.$store.dispatch('kwlist/LOAD_KWLISTS')
    this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
